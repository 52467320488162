import 'den-design-system/style.css';

import { DDSButton, DDSModal, DDSSelect } from 'den-design-system';

import React from 'react';

// import 'den-design-system/src/index.css';

export function Modal() {
  const [openModal, setOpenModal] = React.useState(false);
  const Gender = [
    {
      label: 'Male',
      value: 'male',
    },
    {
      label: 'Female',
      value: 'female',
    },
    {
      label: 'Others',
      value: 'others',
    },
  ];
  const Qualification = [
    {
      label: 'UG',
      value: 'ug',
    },
    {
      label: 'PG',
      value: 'pg',
    },
  ];

  return (
    <div
      className='flex row justify-center items-center h-[32px] ml-6'
      style={{ padding: '50px' }}
    >
      <DDSButton onClick={() => setOpenModal(true)} label='Click me' />
      <DDSModal
        open={openModal}
        setOpen={setOpenModal}
        onCancel={() => setOpenModal(false)}
        onIconClose={() => setOpenModal(false)}
        maskClosable={false}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <div>Profile Details</div>
          <DDSSelect
            label='Gender'
            defaultValue='Male'
            name='projects' // validation purpose
            options={Gender}
            searchable={true}
            placeholder='Choose'
            errorMessage='name can not be empty'
            filterOption={(input, option) =>
              ((option?.label ?? '') as string).toLowerCase().includes(input)
            }
            selectBoxStyle={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          />
          <DDSSelect
            label='Qualification'
            defaultValue='PG'
            name='projects' // validation purpose
            options={Qualification}
            searchable={true}
            placeholder='Choose'
            errorMessage='name can not be empty'
            filterOption={(input, option) =>
              ((option?.label ?? '') as string).toLowerCase().includes(input)
            }
            selectBoxStyle={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          />
        </div>
      </DDSModal>
    </div>
  );
}
