import { DDSTabs } from 'den-design-system';
import React from 'react';
const tabOptions = [
  {
    label: 'General',
    children: (
      <div>
        <div className='settings-general-workspace-section'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <p>Hello</p>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            gap: '8px',
            flexDirection: 'column',
          }}
        ></div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          <p>Radio memberWorkspaceOptions</p>
        </div>
      </div>
    ),
  },
  {
    label: 'Plans and Billings',
    children: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <p>Hello</p>
        <div
          style={{
            paddingTop: '60px',
          }}
        >
          <p>Lorem ipsum</p>
        </div>
      </div>
    ),
  },
];

const Tabs = () => {
  return (
    <div
      style={{
        width: '1000px',
        height: '500px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <DDSTabs tabOptions={tabOptions} />
    </div>
  );
};

export default Tabs;
