import 'den-design-system/style.css';

import { DDSButton } from 'den-design-system';

// import 'den-design-system/src/index.css';

export function Button() {
  return (
    <div
      style={{
        width: '1000px',
        height: '500px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
      }}
    >
      <DDSButton
        className='px-12 items-center rounded-md flex !w-64 border'
        type='outline'
        shape='roundedRectangle'
        style={{ borderColor: '2px solid green', color: 'pink' }}
        // style={{
        //   color: 'green',
        //   borderColor: 'red',
        //   background: 'white',
        // }}
        label='Manage Organizations'
      />

      <DDSButton
        className='px-12 items-center rounded-md flex !w-64 border'
        type='filled'
        shape='rectangle'
        label='Manage Environment'
        buttonHoverBackgroundColor='grey'
      />

      <DDSButton
        className='px-12 items-center rounded-md flex !w-64 border'
        type='ghost'
        shape='rectangle'
        label='Manage Environment'
        buttonHoverBackgroundColor='grey'
      />
    </div>
  );
}
