import {
  DDSButton,
  DDSMenu,
  DDSMenuList,
  DDSTypography,
} from 'den-design-system';

import React from 'react';

const DropDown = () => {
  const [open, setOpen] = React.useState(false);

  return (
    <div
      style={{
        width: '1000px',
        height: '500px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <DDSButton
        label='Dropdown'
        onClick={() => {
          setOpen(true);
        }}
      />
      <DDSMenu
        open={open}
        className='bg-red-800'
        style={{
          background: 'red !important',
        }}
        onClose={() => {
          setOpen(false);
        }}
      >
        {/* <DDSMenuList
          style={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            gap: 100,
            maxHeight: 336,
            minWidth: 10,
            width: 23,
          }}
        >
          <DDSTypography.Paragraph size='para' style={{ marginBottom: 0 }}>
            Placeholder
          </DDSTypography.Paragraph>
        </DDSMenuList>
        <DDSMenuList
          style={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            gap: 10,
            maxHeight: 336,
            width: 232,
          }}
        >
          <DDSTypography.Paragraph size='para' style={{ marginBottom: 0 }}>
            Placeholder
          </DDSTypography.Paragraph>
        </DDSMenuList>
        <DDSMenuList
          style={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            gap: 10,
            maxHeight: 336,
            width: 232,
          }}
        >
          <DDSTypography.Paragraph size='para' style={{ marginBottom: 0 }}>
            Placeholder
          </DDSTypography.Paragraph>
        </DDSMenuList>
        <DDSMenuList
          style={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            gap: 10,
            maxHeight: 336,
            width: 232,
          }}
        >
          <DDSTypography.Paragraph size='para' style={{ marginBottom: 0 }}>
            Placeholder
          </DDSTypography.Paragraph>
        </DDSMenuList> */}
      </DDSMenu>
    </div>
  );
};

export default DropDown;
