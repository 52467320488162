import 'den-design-system/style.css';

import { DDSChips } from 'den-design-system';
import { FaBeer } from 'react-icons/fa';

// import 'den-design-system/src/index.css';

export function Chip() {
  return (
    <div
      style={{
        width: '1000px',
        height: '500px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <DDSChips
        label='We are in'
        startIcon={<FaBeer />}
        endIcon={<FaBeer />}
        type='soft'
        shape='roundedRectangle'
      />

      <DDSChips
        style={{ width: '300px' }}
        label='We are not in'
        startIcon={<FaBeer />}
        endIcon={<FaBeer />}
        type='filled'
        shape='rounded'
      />
    </div>
  );
}
