import { DDSContextSwitcherTab } from 'den-design-system';
import React from 'react';

const ContextTabs = () => {
  const tabOptions = [
    {
      label: 'placeholder 1',
      children: <p>Content 1</p>,
    },
    {
      label: 'placeholder 1',
      children: <p>Content 1</p>,
    },
    {
      label: 'placeholder 1',
      children: <p>Content 1</p>,
    },
  ];
  return (
    <div
      style={{
        width: '1000px',
        height: '500px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <DDSContextSwitcherTab shape='roundedRectangle' tabOptions={tabOptions} />
    </div>
  );
};

export default ContextTabs;
