import { DDSFileUpload } from 'den-design-system';
import React from 'react';

const FileUpload = () => {
  return (
    <div
      style={{
        width: '1000px',
        height: '500px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <DDSFileUpload shape='rectangle' variant='button' />
    </div>
  );
};

export default FileUpload;
