import { DDSStepper } from 'den-design-system';
import React from 'react';

const Stepper = () => {
  const steps = [
    { title: 'Step 1', description: 'Step 1 Content' },
    { title: 'Step 2', description: 'Step 2 Content' },
    { title: 'Step 3', description: 'Step 3 Content' },
  ];
  return (
    <div
      style={{
        width: '1000px',
        height: '500px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <DDSStepper id='stepper' steps={steps} activeStep={2} />
    </div>
  );
};

export default Stepper;
