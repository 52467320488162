import { DDSAccordion } from 'den-design-system';
import React from 'react';

const Accordion = () => {
  const accordionOptions = [
    {
      header: 'Panel header 1',
      content: (
        <p>
          'Lorem ipsum, or lipsum as it is sometimes known, is dummy text used
          in laying out print, graphic or web designs.'
        </p>
      ),
      key: '1',
    },
    {
      header: 'Panel header 2',
      content: (
        <p>
          'Lorem ipsum, or lipsum as it is sometimes known, is dummy text used
          in laying out print, graphic or web designs.'
        </p>
      ),
      key: '2',
    },
    {
      header: 'Panel header 3',
      content: (
        <p>
          'Lorem ipsum, or lipsum as it is sometimes known, is dummy text used
          in laying out print, graphic or web designs.'
        </p>
      ),
      key: '3',
    },
  ];

  return (
    <div
      style={{
        width: '1000px',
        height: '500px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
      }}
    >
      <DDSAccordion
        variant='headingOutlined'
        AccordionOptions={accordionOptions}
        openMultiple={false}
      />
    </div>
  );
};

export default Accordion;
