import { DDSAvatar } from 'den-design-system';
import React from 'react';

const Avatar = () => {
  const placeholder = ['A', 'D', 'S', 'M', 'F', 'D'];
  return (
    <div
      style={{
        width: '1000px',
        height: '500px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
      }}
    >
      <DDSAvatar
        style={{
          background: 'red',
        }}
        placeHolder={placeholder}
        maxCount={3}
      />
    </div>
  );
};

export default Avatar;
