import 'react-datepicker/dist/react-datepicker.css';
import './App.css';
import './index.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
  DDSSelectV2,
  DDSToast,
  DDSTypography,
  DesignSystemEnum,
  ThemeContext,
} from 'den-design-system';
import { Menu, MenuItem, Sidebar } from 'react-pro-sidebar';
import { useContext, useEffect, useState } from 'react';

import Accordion from './components/accordion';
import Avatar from './components/avatar';
import BreadCrumbs from './components/breadcrumbs';
import { Button } from './components/button';
import { Chip } from './components/chip';
import ContextTabs from './components/contextTabs';
import DropDown from './components/dropdown';
import FileUpload from './components/fileUpload';
import { Laoder } from './components/loader';
import { Modal } from './components/modal';
import Pagination from './components/pagination';
import RangePicker from './components/rangePicker';
import { Select } from './components/select';
import Stepper from './components/stepper';
import { Switch } from './components/swicth';
import Table from './components/table';
import Tabs from './components/tabs';
import { Toast } from './components/toast';
import logo from './assests/svg/logo.svg';

function App() {
  const { saveTheme } = useContext(ThemeContext);
  const [selectedDesignSystem, setSelectedDesignSystem] =
    useState<string>('ANT_D');
  const [st, setSt] = useState<any>();
  const [dateRange, setDateRange] = useState<any>([null, null]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    saveTheme({
      designSystem:
        selectedDesignSystem === DesignSystemEnum.MUI
          ? DesignSystemEnum.MUI
          : selectedDesignSystem === DesignSystemEnum.ANT_D
          ? DesignSystemEnum.ANT_D
          : DesignSystemEnum.TAILWIND,
      color: {
        primary: '#FFFF00',
        component: {
          Tour: {},
          ContentSwitcherTabs: {
            activeTabColor: 'red',
            inActiveTabTextColor: 'blue',
            activeTabTextColor: 'black',
            contentSwitcherBackgroundColor: 'pink',
          },
          Accordion: {
            activeHeadingColor: 'green',
            outlineColor: 'green',
            activeOutlined: {
              outlineColor: 'yellow',
              backgroundColor: 'blue',
            },
            headingOutlined: {
              activeHeadingBorder: 'yellow',
            },
          },
          Pagination: {
            buttonHoverColor: 'red',
            activeColor: 'pink',
            inActiveColor: 'blue',
            hoverColor: 'green',
          },
          Tabs: {
            activeTabTextColor: 'red',
            inactiveTabTextColor: 'blue',
          },
          BreadCrumbs: {
            activeColor: 'red',
            inactiveColor: 'green',
          },
          Stepper: {
            inactiveStepBorder: 'orange',
            activeStepFont: 'red',
            inactiveStepFont: 'blue',
          },
        },
      },
    });
  }, [selectedDesignSystem]);

  const projects = [
    {
      label: 'ANT_D',
      value: 'ANT_D',
    },
    {
      label: 'MUI',
      value: 'MUI',
    },
    {
      label: 'TAILWIND',
      value: 'TAILWIND',
    },
  ];

  return (
    <div style={{ width: '1515px' }}>
      <DDSToast position={'bottom-right'} variant='bottomBorder' />
      <div
        style={{
          width: '100%',
          height: '70px',
          background: 'grey',
          position: 'sticky',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '40px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <DDSTypography.Title type='h3'>
            Den Design Systems
          </DDSTypography.Title>
          <img src={logo} alt='' height={'20px'} />
        </div>
        <div style={{ position: 'relative' }}>
          <div
            className='flex row justify-center items-center h-[32px] ml-6'
            style={{ padding: '50px' }}
          >
            <div className='px-3.5 border-[1px] h-[32px] border-gray-300 rounded-l-md text-black bg-[#F2F5F8] bg-white font-semibold py-[1px]'>
              Design System
            </div>
            {/* <DDSButton></DDSButton> */}
            <div className='selector'>
              <DDSSelectV2
                name='projects' // validation purpose
                options={projects}
                defaultValue='MUI'
                value={selectedDesignSystem}
                searchable={true}
                placeholder='Choose'
                multiSelect={false}
                errorMessage='name can not be empty'
                filterOption={(input, option) =>
                  ((option?.label ?? '') as string)
                    .toLowerCase()
                    .includes(input)
                }
                selectBoxStyle={{
                  backgroundClip: 'white',
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
                onSelect={(value: any) => {
                  setSelectedDesignSystem(value);
                  console.log(':: Value ::', value);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          height: '100%',
          paddingTop: '5px',
        }}
      >
        <div style={{ display: 'flex', height: '100%' }}>
          <Sidebar>
            <Menu>
              <MenuItem
                style={{ color: 'grey' }}
                onClick={() => {
                  window.location.href = '/';
                }}
              >
                Select
              </MenuItem>
              <MenuItem
                style={{ color: 'grey' }}
                onClick={() => {
                  window.location.href = '/modal';
                }}
              >
                Modal
              </MenuItem>
              <MenuItem
                style={{ color: 'grey' }}
                onClick={() => {
                  window.location.href = '/loader';
                }}
              >
                Loader
              </MenuItem>
              <MenuItem
                style={{ color: 'grey' }}
                onClick={() => {
                  window.location.href = '/Toast';
                }}
              >
                Toast
              </MenuItem>
              <MenuItem
                style={{ color: 'grey' }}
                onClick={() => {
                  window.location.href = '/switch';
                }}
              >
                Switch
              </MenuItem>
              <MenuItem
                style={{ color: 'grey' }}
                onClick={() => {
                  window.location.href = '/button';
                }}
              >
                Button
              </MenuItem>
              <MenuItem
                style={{ color: 'grey' }}
                onClick={() => {
                  window.location.href = '/chip';
                }}
              >
                Chip
              </MenuItem>
              <MenuItem
                style={{ color: 'grey' }}
                onClick={() => {
                  window.location.href = '/avatar';
                }}
              >
                Avatar
              </MenuItem>
              <MenuItem
                style={{ color: 'grey' }}
                onClick={() => {
                  window.location.href = '/accordion';
                }}
              >
                Accordion
              </MenuItem>
              <MenuItem
                style={{ color: 'grey' }}
                onClick={() => {
                  window.location.href = '/breadcrumbs';
                }}
              >
                BreadCrumbs
              </MenuItem>
              <MenuItem
                style={{ color: 'grey' }}
                onClick={() => {
                  window.location.href = '/context-tabs';
                }}
              >
                Context Tabs
              </MenuItem>
              <MenuItem
                style={{ color: 'grey' }}
                onClick={() => {
                  window.location.href = '/dropdown';
                }}
              >
                Drop down
              </MenuItem>
              <MenuItem
                style={{ color: 'grey' }}
                onClick={() => {
                  window.location.href = '/table';
                }}
              >
                Table
              </MenuItem>
              <MenuItem
                style={{ color: 'grey' }}
                onClick={() => {
                  window.location.href = '/tabs';
                }}
              >
                Tabs
              </MenuItem>
              <MenuItem
                style={{ color: 'grey' }}
                onClick={() => {
                  window.location.href = '/range-picker';
                }}
              >
                RangePicker
              </MenuItem>
              <MenuItem
                style={{ color: 'grey' }}
                onClick={() => {
                  window.location.href = '/stepper';
                }}
              >
                Stepper
              </MenuItem>
              <MenuItem
                style={{ color: 'grey' }}
                onClick={() => {
                  window.location.href = '/pagination';
                }}
              >
                Pagination
              </MenuItem>
              <MenuItem
                style={{ color: 'grey' }}
                onClick={() => {
                  window.location.href = '/file-upload';
                }}
              >
                File upload
              </MenuItem>
            </Menu>
          </Sidebar>
        </div>
        {/* <DDSTable
          columns={[
            {
              heading: 'Name',
              dataLabel: 'name',
              render: (text: any) => <a>{text}</a>,
            },
            {
              heading: 'Age',
              dataLabel: 'age',
              render: (text: any) => <div>{text}</div>,
            },
            {
              heading: 'Address',
              dataLabel: 'address',
              render: (text: any) => <div>{text}</div>,
            },
          ]}
          onSelect={(select) => {
            console.log(select);
            setSt(select);
          }}
          selectable
          data={[
            {
              // id: '1',
              name: 'John Brown',
              age: 32,
              address: 'New York No. 1 Lake Park',
            },
            {
              // id: '2',
              name: 'Jim Greenmmm',
              age: 42,
              address: 'London No. 1 Lake Park',
            },
            {
              // id: '3',
              name: 'Joe Black',
              age: 32,
              address: 'Sydney No. 1 Lake Park',
            },
          ]}
        /> */}

        <div>
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<Select />} />
              <Route path='/modal' element={<Modal />} />
              <Route path='/loader' element={<Laoder />} />
              <Route path='/switch' element={<Switch />} />
              <Route path='/Toast' element={<Toast />} />
              <Route path='/Button' element={<Button />} />
              <Route path='/Chip' element={<Chip />} />
              <Route path='/avatar' element={<Avatar />} />
              <Route path='/accordion' element={<Accordion />} />
              <Route path='/breadcrumbs' element={<BreadCrumbs />} />
              <Route path='/context-tabs' element={<ContextTabs />} />
              <Route path='/dropdown' element={<DropDown />} />
              <Route path='/table' element={<Table />} />
              <Route path='/tabs' element={<Tabs />} />
              <Route path='/range-picker' element={<RangePicker />} />
              <Route path='/stepper' element={<Stepper />} />
              <Route path='/pagination' element={<Pagination />} />
              <Route path='/file-upload' element={<FileUpload />} />
            </Routes>
          </BrowserRouter>
        </div>
      </div>
    </div>
  );
}

export default App;
