import { DDSPagination } from 'den-design-system';
import React from 'react';

const Pagination = () => {
  const [currentPageInput, setCurrentPage] = React.useState(1);
  return (
    <div
      style={{
        width: '1000px',
        height: '500px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <DDSPagination
        onChange={(page) => {
          setCurrentPage(page);
        }}
        totalCount={50}
        rowsPerPage={4}
        currentPage={currentPageInput}
      />
    </div>
  );
};

export default Pagination;
