import { DDSTable } from 'den-design-system';
import React from 'react';

const columnData = [
  {
    dataLabel: 'planName',
    heading: 'Plan Name',
    render: (planName: any) => planName,
  },
  {
    dataLabel: 'status',
    heading: 'Status',
    render: (status: any) => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '15px',
          }}
        >
          Status
        </div>
      );
    },
  },
  {
    dataLabel: 'planDetails1',
    heading: 'Plan Details1',
    render: (planName: any) => planName,
  },
  {
    dataLabel: 'planDetails2',
    heading: 'Plan Details2',
    render: (planName: any) => planName,
  },
  {
    dataLabel: 'planDetails3',
    heading: 'Plan Details3',
    render: (planName: any) => planName,
  },
  {
    dataLabel: 'planDetails4',
    heading: 'Plan Details4',
    render: (planName: any) => planName,
  },
];

const tableData = [
  {
    planName: 'Family Plan',
    status: 'Active',
    planDetails1: 'Plan Details1',
    planDetails2: 'Plan Details2',
    planDetails3: 'Plan Details3',
    planDetails4: 'Plan Details4',
  },
  {
    planName: 'Family Plan',
    status: 'Active',
    planDetails1: 'Plan Details1',
    planDetails2: 'Plan Details2',
    planDetails3: 'Plan Details3',
    planDetails4: 'Plan Details4',
  },
  {
    planName: 'Family Plan',
    status: 'Active',
    planDetails1: 'Plan Details1',
    planDetails2: 'Plan Details2',
    planDetails3: 'Plan Details3',
    planDetails4: 'Plan Details4',
  },
  {
    planName: 'Family Plan',
    status: 'Active',
    planDetails1: 'Plan Details1',
    planDetails2: 'Plan Details2',
    planDetails3: 'Plan Details3',
    planDetails4: 'Plan Details4',
  },
  {
    planName: 'Family Plan',
    status: 'Active',
    planDetails1: 'Plan Details1',
    planDetails2: 'Plan Details2',
    planDetails3: 'Plan Details3',
    planDetails4: 'Plan Details4',
  },
];

const Table = () => {
  return (
    <div
      style={{
        width: '1000px',
        height: '500px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <DDSTable data={tableData} selectable={true} columns={columnData} />
    </div>
  );
};

export default Table;
