import 'den-design-system/style.css';

import { DDSButton, DDSToast, DDSToastNotification } from 'den-design-system';

import { useState } from 'react';

// import 'den-design-system/src/index.css';
type positions = 'top-right' | 'top-left' | 'bottom-left' | 'bottom-right';
export function Toast() {
  const [position, setPosition] = useState<positions>('bottom-left');
  // const position: positions[] = [
  //   'top-right',
  //   'top-left',
  //   'bottom-left',
  //   'bottom-right',
  // ];
  // const randomIndex = Math.floor(Math.random() * 2);
  // const randomPosition = position[randomIndex];
  const handleToastClick = () => {
    const randomNumber = Math.floor(Math.random() * 4) + 1;
    if (randomNumber === 1) {
      DDSToastNotification.info('This is info toast');
      setPosition('top-left');
    }
    if (randomNumber === 2) {
      DDSToastNotification.success('This is success toast');
      setPosition('top-right');
    }
    if (randomNumber === 3) {
      DDSToastNotification.warning('This is warning toast');
      setPosition('bottom-left');
    }
    if (randomNumber === 4) {
      DDSToastNotification.error('This is error toast');
      setPosition('bottom-right');
    }
  };
  return (
    <div
      style={{
        width: '1000px',
        height: '500px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <DDSButton label='show tost' onClick={handleToastClick} />
      <DDSToast position={position} variant='bottomBorder' />
    </div>
  );
}
