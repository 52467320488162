import 'den-design-system/style.css';

import { DDSSwitch } from 'den-design-system';

// import 'den-design-system/src/index.css';

export function Switch() {
  return (
    <div
      style={{
        width: '1000px',
        height: '500px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
      }}
    >
      <DDSSwitch
        // value={true}
        defaultChecked={true}
        shape='rectangle'
        onChange={(value) => {
          console.log(value);
        }}
      />

      <DDSSwitch
        defaultChecked={false}
        shape='rounded'
        onChange={(value) => {
          console.log(value);
        }}
      />

      <DDSSwitch
        defaultChecked={true}
        disabled={true}
        shape='roundedRectangle'
        onChange={(value) => {
          console.log(value);
        }}
      />
    </div>
  );
}
