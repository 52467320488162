// import 'den-design-system/style.css';

import { DDSSelect, DDSSelectV2 } from 'den-design-system';

// import 'den-design-system/src/index.css';

export function Select() {
  const projects = [
    {
      label: 'Vigil',
      value: 'th',
    },
    {
      label: 'Vijok',
      value: 'ok',
    },
    {
      label: 'Eipuik',
      value: 'kol',
    },
    {
      label: 'Vigil',
      value: 'vigil',
    },
    {
      label: 'EIP',
      value: 'eip',
    },
    {
      label: 'FontViz',
      value: 'fontviz',
    },
  ];

  return (
    <div
      className='flex row justify-center items-center h-[32px] ml-6'
      style={{ padding: '50px' }}
    >
      <div className='px-3.5 border-[1px] h-[32px]  rounded-l-md text-black bg-[#F2F5F8] font-semibold py-[1px]'>
        Project
      </div>
      {/* <DDSButton></DDSButton> */}
      <div className='selector'>
        <DDSSelectV2
          name='projects' // validation purpose
          options={projects}
          searchable={true}
          required={true}
          multiSelect={false}
          placeholder='Choose'
          errorMessage='name can not be empty'
          filterOption={(input, option) =>
            ((option?.label ?? '') as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          selectBoxStyle={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
          onSelect={(value) => {
            console.log(':: Value ::', value);
          }}
        />
      </div>
    </div>
  );
}
