import dayjs, { Dayjs } from 'dayjs';

import { DDSRangePicker } from 'den-design-system';
import React from 'react';

const RangePicker = () => {
  const [open, setOpen] = React.useState(false);
  return (
    <div
      style={{
        width: '1000px',
        height: '500px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <DDSRangePicker
        rangePresets={true}
        onBlur={() => {
          setOpen(false);
        }}
        onFocus={() => {
          console.log('focus');

          setOpen(true);
        }}
        open={open}
        onChange={() => {
          console.log('change');
        }}
        //value={[dayjs('2024-02-03'), dayjs('2024-02-10')]}
        name='hello'
      />
    </div>
  );
};

export default RangePicker;
