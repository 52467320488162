import { DDSBreadcrumbs } from 'den-design-system';
import React from 'react';

const BreadCrumbs = () => {
  const breadcrumbsOptions = [
    {
      label: (
        <div className='flex items-center justify-center gap-[5px]'>One</div>
      ),
      link: '/',
    },
    {
      label: (
        <div className='flex items-center justify-center gap-[5px]'>Two</div>
      ),
      link: '/avatar',
    },
    {
      label: (
        <div className='flex items-center justify-center gap-[5px]'>Three</div>
      ),
      link: '/',
    },
    {
      label: (
        <div className='flex items-center justify-center gap-[5px]'>Four</div>
      ),
      link: '/',
    },
    {
      label: (
        <div className='flex items-center justify-center gap-[5px]'>Five</div>
      ),
      link: '/',
    },
    {
      label: (
        <div className='flex items-center justify-center gap-[5px]'>Six</div>
      ),
      link: '/',
    },
  ];

  return (
    <div
      style={{
        width: '1000px',
        height: '500px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <DDSBreadcrumbs
        breadcrumbsOption={breadcrumbsOptions}
        onClick={(link, e) => {
          console.log('link', link);
        }}
        maxCrumbs={80}
        onBlur={(link) => {
          console.log('blur', link.target);
        }}
      />
    </div>
  );
};

export default BreadCrumbs;
