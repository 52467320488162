import 'den-design-system/style.css';

import { DDSLoader } from 'den-design-system';

// import 'den-design-system/src/index.css';

export function Laoder() {
  return (
    <div
      style={{
        width: '1000px',
        height: '500px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <DDSLoader loading={true} size={'medium'} />

      <DDSLoader loading={true} size='xs' />

      <DDSLoader loading={true} size='large' />
    </div>
  );
}
